import React from 'react';
import { useHistory } from 'react-router-dom';
import ContentHeader from './ContentHeader';
import LoadingIndicator from './LoadingIndicator';
import {  useFetchHtml, URL_SX, URL_GS } from './ContentAPI';

const scripConvert = {'1_cor':'146', '2_ne':'206', eghtwtnss:'204', ether:'218', fac:'403', john:'143', js_h:'405', mal:'139', moro:'219', od:'303', rev:'166', sec:'302', thrwtnss:'203', w_of_m:'211'}

function Content() {
    const history = useHistory();
    const { isLoading, html, headerHtml, queryType } = useFetchHtml()
    const pathArr = history.location.pathname.substring(1).split('ct');

    const handleSXandGSfunctions = (link, span) => {
        const query = {
            sx: `${URL_SX}${span.id}`,
            gs: `${URL_GS}${span.id}`
        }
        // Fetch the data needed to navigate to the correct scripture
        fetch(query[link.id])
            .then(response => response.json())
            .then(response => {
                const jst = (response.flag && response.flag === 'J') || (response.jst && response.jst === 'J') ? true : false;
                if (link.id === 'gs') {
                    history.push(`/${pathArr[0]}ct/scripture/${response.book}-${response.chapter}/verses=${response.verses}-jst=${jst ? 'true' : 'false'}`);
                }
                if (link.id === 'sx') {
                    history.push(`/citation/${response.book}/${response.chap}/${response.verses}/${jst ? 'jst' : 'reg'}/Any-1830-2021-all-s-f/ct${pathArr[1]}`);
                }
            }
        );
    }

    const handleGetScriptureFunction = (linkOnClick) => {
        const refArr = linkOnClick[1].replace("'?", "").replace("')", "").split('&');
        const refObj = {};
        refArr.forEach(ref => {
            ref = ref.split('=');
            refObj[ref[0]] = ref[0] === 'jst' ? (ref[1].toLowerCase() === 'jst' ? true : null) : ref[1];
        });
        history.push(`/${pathArr[0]}ct/scripture/${scripConvert[refObj.book]}-${refObj.chap}/verses=${refObj.verses === '' ? 'all' : refObj.verses}-jst=${refObj.jst ? 'true' : 'false'}`);
    }


    // Handle clicks on links inside the content
    // https://www.py4u.net/discuss/989550
    function clickHandler(e) {
        // `target` is the element clicked on `currentTarget` is the div we hooked the event on
        const span = e.target.closest("span");
        const link = e.target.closest("a");

        if (link && e.currentTarget.contains(link)) {
            // ...do your state change...
            if (link.id === 'sx' || link.id === 'gs') {
                handleSXandGSfunctions(link, span);
            }

            const linkClick = link.getAttribute('onclick');
            if (linkClick) {
                const linkOnClick = linkClick.split('(');
                if ( linkOnClick[0] === 'getScripture') {
                    handleGetScriptureFunction(linkOnClick);
                }
            }
        }
    }

    if (isLoading) {
        return <LoadingIndicator />
    }

    return (
        <div>
            <ContentHeader data={headerHtml} />
            <div
                id="centercontent"
                className={queryType === 'scripture' || queryType === 'scripQuery' ? 'scripturecontent nano centerscrip' : ''}
                dangerouslySetInnerHTML={{ __html: html }}
                onClick={clickHandler}
            >
            </div>
        </div>
    );
}

export default Content;