import { useEffect, useState } from "react";
import { parseHTML } from './LibraryAPI';
import { useLocation } from "react-router-dom";

/*----------------------------------------------------------------------
 *                      CONSTANTS
 */
const URL_SEARCH_RESULTS = "https://scriptures.byu.edu/citation_index/search_results_ajax/";
const URL_SEARCH = 'https://scriptures.byu.edu/citation_index/search_ajax/';

/*----------------------------------------------------------------------
 *                      PRIVATE HELPERS
 */
const isolateMainSearchContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = {talksearch: {speakers: [], years: [], labels: []}, scripsearch: []};

    let scripsearch = htmlstring.querySelector('#searchScripturesWrapper');
    let labellist = scripsearch.querySelectorAll('label');
    labellist.forEach((label) => {
        let labobj = {};
        labobj.title = label.title;
        labobj.text = label.innerHTML.split('>')[1].trim();
        labobj.inputid = label.querySelector('input').id;

        returnobject.scripsearch.push(labobj);
    });

    let talksearch = htmlstring.querySelector('#searchTalksWrapper');
    let speakersearch = talksearch.querySelector('#speakerSearch');
    let speakerlist = speakersearch.querySelectorAll('option');
    speakerlist.forEach((speaker) => {
        let speakerobj = {}
        speakerobj.id = speaker.id;
        speakerobj.value = speaker.value;
        speakerobj.text = speaker.innerHTML;
        returnobject.talksearch.speakers.push(speakerobj)
    });

    let yearsearch = talksearch.querySelector('#startYearSearch');
    let yearlist = yearsearch.querySelectorAll('option');
    yearlist.forEach((year) => {
        returnobject.talksearch.years.push(year.value)
    });

    let talklabellist = talksearch.querySelectorAll('label');
    talklabellist.forEach((label) => {
        let labobj = {};
        labobj.title = label.title;
        labobj.text = label.innerHTML.split('>')[1].trim();
        labobj.inputid = label.querySelector('input').id;

        returnobject.talksearch.labels.push(labobj);
    });

    return returnobject;
};

const isolateSearchResultsContent = function (htmlstring, searchText, setType) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = {summary: '', totalresults: 0, }

    let summary = htmlstring.querySelector('div.qSummary')
    if (!summary) {
        returnobject.summary = "Search Failed. Please Try Again.";
    } else {
        if (summary.childNodes.length < 5) {
            returnobject.totalresults = 0;
        } else {
            returnobject.totalresults = parseInt(summary.childNodes[3].innerHTML);
        }

        // Dynamically build the search description to display with results
        let criteria = searchText[0].split('&');
        let num = returnobject.totalresults;
        searchText[1] = decodeURIComponent(searchText[1]);
        if (searchText[2]) {
            searchText[2] = decodeURIComponent(searchText[2]);
        }

        let sumString = [`<b>${num}</b> result${num === 1 ? '' : 's'} for [<b>${searchText[1]}</b>] in`];

        if (criteria[0] === 't') {
            setType('talks');
            sumString.push("talks");
            if (searchText[2] && searchText[2] !== 'Any') {
                sumString.push(`by ${searchText[2]}`);
            }

            const corpus = criteria[4].split('');
            if (corpus.length < 3) {
                let corpusArr = [];
                corpus.forEach((corp) => {
                    if (corp === 'g') {
                        corpusArr.push("Gen. Conf.");
                    }
                    if (corp === 'j') {
                        corpusArr.push("J. of D.");
                    }
                    if (corp === 't') {
                        corpusArr.push("TPJS");
                    }
                });
                sumString.push(`from ${corpusArr.join(', ')}`);
            }

            // RAN OUT OF TIME AND HAD TO HARD CODE THESE YEARS
            // THEY SHOULD BE CHANGED PROGRAMATICALLY TO UPDATE
            // WITH THE YEARS AVAILABLE IN THE SEARCH FORM
            const yearmin = 1830;
            const yearmax = 2021;
            if (criteria[2] > yearmin && criteria[3] === yearmax) {
                sumString.push(`since ${criteria[2]}`);
            }
            if (criteria[2] === yearmin && criteria[3] < yearmax) {
                sumString.push(`before ${criteria[3]}`)
            }
            if (criteria[2] > yearmin && criteria[3] < yearmax) {
                sumString.push(`between ${criteria[2]} and ${criteria[3]}`)
            }
        }
        if (criteria[0] === 's') {
            setType('scriptures');
            if (criteria[1] === 'onfbdp'){
                sumString.push("scriptures");
            }
            else {
                const corpus = criteria[1].split('');
                let corpusArr = [];
                corpus.forEach((corp) => {
                    if (corp === 'o') {
                        corpusArr.push("OT");
                    }
                    if (corp === 'n') {
                        corpusArr.push("NT");
                    }
                    if (corp === 'f') {
                        corpusArr.push("JST Footnotes");
                    }
                    if (corp === 'b') {
                        corpusArr.push("B of M");
                    }
                    if (corp === 'd') {
                        corpusArr.push("D & C");
                    }
                    if (corp === 'p') {
                        corpusArr.push("P of GP");
                    }
                    if (corp === 'j') {
                        corpusArr.push("JST");
                    }
                });
                sumString.push(corpusArr.join(', '));
            }
        }

        returnobject.summary = sumString.join(' ');
    }

    // CODE FOR PAGINATION INSTEAD OF INIFINITE SCROLLER
    // let pagIndicator = htmlstring.querySelector('div.pagination');
    // if (!pagIndicator) {
    //     returnobject.pagination.status = false;
    // }
    // else {
    //     let pagList = pagIndicator.querySelectorAll('li');
    //     pagList.forEach((pag, idx) => {
    //         let pagObj = {};

    //         pagObj.class = pag.className;
    //         if (pag.className === 'active') {
    //             let span = pag.querySelector('span');
    //             pagObj.text = span.innerHTML;
    //             pagObj.innerClass = span.className;
    //             pagObj.toResNum = null;
    //         } else {
    //             let a = pag.querySelector('a');
    //             pagObj.text =  a.innerHTML.replaceAll("&gt;", ">").replaceAll("&lt;", "<");
    //             pagObj.innerClass = a.className;
    //             pagObj.toResNum = parseInt(a.getAttribute('href').split("'")[1]);
    //         }
    //         returnobject.pagination.nodes.push(pagObj);
    //     });
    //     if (pagList[0].className === 'active') {
    //         returnobject.pagination.status = 'first';
    //     }
    //     else if (pagList[pagList.length - 1].className === 'active') {
    //         returnobject.pagination.status = 'last';
    //     }
    //     else {
    //         returnobject.pagination.status = 'middle';
    //     }
    // }

    return returnobject;
};

/*----------------------------------------------------------------------
 *                      PUBLIC HOOKS
 */
function useFetchSearchData(component, active) {
    const location = useLocation();
    const [content, setContent] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [type, setType] = useState();

    useEffect(() => {
        const path = location.pathname.substring(1).split('/ct/')[0].split('/');
        const searchText = path[path.length - 1].split('-');

        const query = {
            searchMain: URL_SEARCH,
            searchResults: `${URL_SEARCH_RESULTS}${searchText[0]}0$${encodeURIComponent(searchText[1])}`
        }
        if (active) {
            fetch(query[component])
                .then((response) => response.text())
                .then((response) => {

                    if (component === 'searchMain') {
                        response = isolateMainSearchContent(response);
                    }
                    if (component === 'searchResults') {
                        response = isolateSearchResultsContent(response, searchText, setType);
                    }

                    setContent(response);
                    setIsLoading(false);
                }
            );
        }
    }, [component, active, location.pathname]);

    if (component === 'searchResults') {
        return { content, isLoading, type };
    }
    return { content, isLoading };
}

function useSearchAPI(query, pageNumber, type) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [results, setResults] = useState([]);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        setResults([]);
    }, [query]);

    useEffect(() => {
        setLoading(true);
        setError(false);
        fetch(`${URL_SEARCH_RESULTS}${query[0]}${pageNumber}$${query[1]}`)
            .then((res) => res.text())
            .then((res) => {
                let returnlist = [];
                let htmlstring = parseHTML(res);

                let resultscontainer = htmlstring.querySelector('ul.searchresults');
                if (!resultscontainer) {
                    returnlist.push('None')
                } else {
                    let resultslist = resultscontainer.querySelectorAll('li');
                    resultslist.forEach((result) => {
                        let resobj = {};
                        if (type === 'talks') {
                            resobj.talknum = result.querySelector('a').getAttribute('onclick').split("'")[1];
                            resobj.terms = result.querySelector('a').getAttribute('onclick').split("'")[5];
                        } else {
                            let res = result.querySelector('a').getAttribute('onclick').split("'")[1].replace('?', '').split('&')
                            resobj.book = res[0].split('=')[1];
                            resobj.chapter = res[1].split('=')[1];
                            resobj.verses = res[2].split('=')[1];
                            resobj.jst = res[3].split('=')[1] === '' ? false : true;
                        }
                        resobj.title = result.querySelector('div.resultTitle').innerHTML;
                        resobj.context = result.querySelector('div.resultContext').innerHTML;

                        returnlist.push(resobj);
                    });
                }

                setResults((prevResults) => {
                    return [...prevResults, ...returnlist];
                });
                setHasMore(returnlist.length > 0);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setError(true);
            }
        );
    }, [query, pageNumber, type]);

    return { loading, error, results, hasMore };
}

/*----------------------------------------------------------------------
 *                      EXPORTS
 */
export {useFetchSearchData, useSearchAPI};