import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

/*----------------------------------------------------------------------
 *                      CONSTANTS
 */
const URL_CONFERENCE = 'https://scriptures.byu.edu/citation_index/gc_ajax/';
const URL_JOD = 'https://scriptures.byu.edu/citation_index/jod_ajax/';
const URL_STPJS = "https://scriptures.byu.edu/citation_index/stpjs_ajax/";
const URL_TI = 'https://scriptures.byu.edu/citation_index/ti_ajax/';
const URL_SCRIPTURES = "https://scriptures.byu.edu/scriptures/scriptures_ajax/";

/*----------------------------------------------------------------------
 *                      PRIVATE HELPERS
 */
const isolateConferenceListContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let conferencelist = htmlstring.querySelector("ul.conflist");
    let alist = conferencelist.querySelectorAll("a");
    alist.forEach((atag) => {
        let confobj = {};
        let name = atag.childNodes[0].nodeValue;
        let date = name.split('\u2013');
        confobj.name = name;
        confobj.year = date[0]
        confobj.month = date[1]
        confobj.extname = atag.querySelector('span').innerHTML.trim();
        returnobject.push(confobj);
    });

    return returnobject;
};

const isolateConferenceTalkContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let titles = htmlstring.querySelectorAll("div.sessiontitle");
    titles.forEach((title) =>
        returnobject.push({ title: title.innerHTML.trim() })
    );

    let sessionlist = htmlstring.querySelectorAll("ul.talksblock");
    sessionlist.forEach((lists, idx) => {
        let talklist = lists.querySelectorAll("li");
        let sessiontalklist = [];

        talklist.forEach((talk) => {
            let talkdetails = {};
            let alist = talk.querySelectorAll('a');
            talkdetails.speaker = talk.querySelector('div.speaker').innerHTML;
            talkdetails.title = talk.querySelector('div.talktitle').innerHTML;
            alist.forEach((a, idx) => {
                let click = a.getAttribute('onclick').split("'");
                if(idx === 0) {
                    talkdetails.talknum = click[1];
                }
                else if(idx === 1) {
                    talkdetails.watchlink = click[3];
                }
                else if(idx === 2) {
                    talkdetails.listenlink = click[3];
                }
            })
            if(talk.querySelector('div.watchtalk')){
                talkdetails.media = true;
            } else {
                talkdetails.media = false;
            }

            sessiontalklist.push(talkdetails);
        })

        returnobject[idx]["talks"] = sessiontalklist;
    });

    return returnobject;
};

const isolateJodListContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let volumelist = htmlstring.querySelector("ul.jodvolumes");
    let alist = volumelist.querySelectorAll("a");
    alist.forEach((atag) => {
        let volobj = {};
        volobj.name = atag.innerHTML;
        let number = atag.getAttribute('onclick').split("'")[1];
        if(number.length < 2) {
            number = `0${number}`;
        }
        volobj.number = number;
        returnobject.push(volobj);
    });

    return returnobject;
};

const isolateJodVolumeContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let discourselist = htmlstring.querySelector("ul.discoursesblock");
    let discourses = discourselist.querySelectorAll("li");
    discourses.forEach((disc) => {
        let discdetails = {};

        discdetails.speaker = disc.querySelector('div.speaker').innerHTML;
        discdetails.title = disc.querySelector('div.talktitle').innerHTML;
        discdetails.number = disc.querySelector('a').getAttribute('onclick').split("'")[1];

        returnobject.push(discdetails);
    });

    return returnobject;
};

const isolateScriptureBookContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let titles = htmlstring.querySelectorAll("b.chaptertitle");

    titles.forEach((title) => {
        let titlenew = title.innerHTML;
        returnobject.push({
            title: titlenew
        })
    });

    if (returnobject.length > 2) {
        returnobject[2].jst = true;
    }

    let chapterblock = htmlstring.querySelectorAll("ul.chaptersblock");
    chapterblock.forEach((chapters, idx) => {
        let alist = chapters.querySelectorAll("a");
        let chapterlist = [];
        alist.forEach((list) => {
            chapterlist.push(parseInt(list.innerHTML.trim()));
        });
        returnobject[returnobject.length > 2 ? idx + 1 : idx]["chapters"] = chapterlist;
    });

    return returnobject;
};

const isolateScriptureVolumeContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let titles = htmlstring.querySelectorAll("div.volumetitle");
    titles.forEach((title) =>
        returnobject.push({ title: title.innerHTML.trim() })
    );

    let volumelist = htmlstring.querySelectorAll("ul.volumecontents");
    volumelist.forEach((lists, idx) => {
        let alist = lists.querySelectorAll("a");
        let booklist = [];
        alist.forEach((list) => {
            booklist.push(list.innerHTML.trim());
        });
        returnobject[idx]["books"] = booklist;
    });

    return returnobject;
};

const isolateSTPJSContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let titles = htmlstring.querySelectorAll("div.sectiontitle");
    titles.forEach((title) =>
        returnobject.push({ title: title.innerHTML.trim() })
    );

    let sectionlist = htmlstring.querySelectorAll("ul.pagesblock");
    sectionlist.forEach((section, idx) => {
        let alist = section.querySelectorAll("a");
        let pages = [];
        alist.forEach((a) => {
            let aitem = {}
            aitem.text = a.innerHTML.trim();
            aitem.link = a.getAttribute('onclick').match(/[^()]+/g)[1];
            pages.push(aitem);
        });
        returnobject[idx]["pages"] = pages;
    });

    return returnobject;
};

const isolateTopicalIndexContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let topiclist = htmlstring.querySelector("ul.topiclist");
    let topics = topiclist.querySelectorAll("li");
    topics.forEach((topic) => {
        let topobj = {};
        topobj.title = topic.querySelector('div.topictitle').innerHTML;
        topobj.subtitle = topic.querySelector('div.topicsubtitle').innerHTML;
        topobj.arrow = (topic.querySelector('div.arrow') ? true : false);
        let nextArr = topic.querySelector('a').getAttribute('onclick').match(/[^()]+/g)[1].split(',');
        if (nextArr.length > 1) {
            topobj.nexttop = topic.querySelector('a').getAttribute('onclick').match(/[^()]+/g)[1].split(',')[0];
            topobj.nexttopciteid = topic.querySelector('a').getAttribute('onclick').match(/[^()]+/g)[1].split(',')[1].replaceAll("'", "").trim();
        } else {
            topobj.nexttop = topic.querySelector('a').getAttribute('onclick').match(/[^()]+/g)[1].split(',')[0];
        }
        returnobject.push(topobj);
    });

    return returnobject;
};

const getNavCrumbText = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnArr = [];

    const crumbUL = htmlstring.querySelector('ul.crumb');
    const crumbs = crumbUL.querySelectorAll('li');
    crumbs.forEach(crumb => {
        const crumbText = crumb.querySelector('span.largecrumb').innerHTML.trim();
        returnArr.push(crumbText);
    })

    return returnArr;
}

/*----------------------------------------------------------------------
 *                      PUBLIC HOOKS
 */


const parseHTML = function(str) {
    let tmp = document.implementation.createHTMLDocument();
    tmp.body.innerHTML = str;
    return tmp;
};

function useFetchLibraryData(component, active, setCrumbTextArr) {
    const [content, setContent] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        const query = {
            scripVolumes: URL_SCRIPTURES,
            scripBook: `${URL_SCRIPTURES}${params.book}`,
            confList: URL_CONFERENCE,
            confTalks: `${URL_CONFERENCE}${params.year}/${params.month}`,
            jodList: URL_JOD,
            jodVolume: `${URL_JOD}${params.volume}`,
            stpjs: URL_STPJS,
            topIdx: `${URL_TI}${!params.id ? 1 : params.id.split('-')[params.id.split('-').length - 1]}`
        }
        if (active) {
            fetch(query[component])
                .then((response) => response.text())
                .then((response) => {

                    if (component === 'scripVolumes') {
                        response = isolateScriptureVolumeContent(response);
                    }
                    if (component === 'scripBook') {
                        response = isolateScriptureBookContent(response);
                    }
                    if (component === 'confList') {
                        response = isolateConferenceListContent(response);
                    }
                    if (component === 'confTalks') {
                        response = isolateConferenceTalkContent(response);
                    }
                    if (component === 'jodList') {
                        response = isolateJodListContent(response);
                    }
                    if (component === 'jodVolume') {
                        response = isolateJodVolumeContent(response);
                    }
                    if (component === 'stpjs') {
                        response = isolateSTPJSContent(response);
                    }
                    if (component === 'topIdx') {
                        setCrumbTextArr(getNavCrumbText(response));
                        response = isolateTopicalIndexContent(response);
                    }

                    setContent(response);
                    setIsLoading(false);
                }
            );
        }
    }, [component, active, params.year, params.month, params.volume, params.id, params.book, setCrumbTextArr]);

    return { content, isLoading };
}

/*----------------------------------------------------------------------
 *                      EXPORTS
 */
export { useFetchLibraryData, parseHTML };