import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import { useFetchSpeakerData } from './SpeakerAPI';

function SpeakerTalks(props) {
    const params = useParams();

    const { content, isLoading } = useFetchSpeakerData('speakerTalks', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <ul className="talksblock">
                    {content.map((talk, idx) => {
                        if(!talk.media) {
                            return(
                                <li key={idx}>
                                    <NavLink
                                        className="waves-effect waves-yellow"
                                        to={`/speakers/${params.letter}/${params.speaker}/ct/talk/${talk.talknum}`}
                                    >
                                        <div className="speaker">{talk.session}</div>
                                        <div className="talktitle">{talk.title}</div>
                                    </NavLink>
                                </li>
                            )
                        }

                        return(
                            <li key={idx}>
                                <NavLink
                                    className="waves-effect waves-yellow"
                                    to={`/speakers/${params.letter}/${params.speaker}/ct/talk/${talk.talknum}`}
                                >
                                    <div className="speaker">{talk.session}</div>
                                    <div className="talktitle talktitlewatch talktitlelisten">{talk.title}</div>
                                </NavLink>
                                <a href={talk.watchlink} target="_blank" rel="noreferrer" className="watchlink" title="Watch talk at lds.org">
                                    <div className="watchtalk">Watch</div>
                                </a>
                                <a href={talk.listenlink} target="_blank" rel="noreferrer" className="listenlink" title="Listen to talk at lds.org" >
                                    <div className="listentalk">Listen</div>
                                </a>
                            </li>
                        )

                    })}
                </ul>
            </div>
        </div>
    )
}

export default SpeakerTalks;
