import React, {useState, useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { dropdownFunctions, useOutsideAlerter } from './ContentHeaderAPI';
import ContentHeaderArrows from './ContentHeaderArrows';
import ContentHeaderLabel from './ContentHeaderLabel';

function ContentHeader(props) {
    const [dropdown, setDropdown] = useState(false);
    const history = useHistory();
    const dropref = useRef();
    const pathArr = history.location.pathname.substring(1).split('ct');
    const data = props.data;

    const toggleDropdown = () => {
        if (dropdown) {
            setDropdown(false);
        } else {
            setDropdown(true);
        }
    }

    useOutsideAlerter(dropref, setDropdown);

    return (
        <div id="centernavbar">
            {!data ?
                <div id="aboutlabel" className="visiblelabel">Loading...</div>
                :
                <>
                    <ContentHeaderLabel data={data} pathArr={pathArr} />
                    {!data.print ?
                        <></>
                        :
                        <button title="print" className="print_button"></button>
                    }
                    {data.rightarrow || data.leftarrow ?
                        <ContentHeaderArrows data={data}/>
                        :
                        <></>
                    }
                    <div className={dropdown ? 'open' : ''} ref={dropref}>
                        <button id={data.dropdownId} className={data.dropdownClass} onClick={() => toggleDropdown()} data-toggle={data.dropdownDatatoggle}></button>
                        <ul className={data.ulClass} role={data.ulRole} aria-labelledby={data.ulLabelledby}>
                            {data.listitems.map((listitem, idx) => {
                                return(
                                    <li key={idx}>
                                        <button
                                            id={listitem.id}
                                            style={listitem.style === "" ? {} : {display: "none"}}
                                            onClick={() => dropdownFunctions[idx](setDropdown, history.push, pathArr[0])}
                                        >{listitem.text}</button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </>
            }
        </div>
    )
}

export default ContentHeader;
