import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';

const SKIP_TO_SCRIP = [201, 202, 203, 204, 301]

function ContentHeaderArrows(props) {
    const data = props.data;
    const history = useHistory();
    const pathArr = history.location.pathname.substring(1).split('/ct/');
    const pathContent = pathArr[1].split('/');
    const queryType = pathContent[0];

    let leftArrowLink;
    let rightArrowLink;
    if (queryType === 'scripture') {
        leftArrowLink = `/${pathArr[0]}/ct/scripture`
                        + `/${SKIP_TO_SCRIP.includes(data.leftarrowbook) ? `${data.leftarrowbook}` : `${data.leftarrowbook}-${data.leftarrowchapter}`}`
                        + `/verses=${data.leftarrowverses}-jst=${data.leftarrowjst ? 'true' : 'false'}`
        rightArrowLink = `/${pathArr[0]}/ct/scripture`
                        + `/${SKIP_TO_SCRIP.includes(data.rightarrowbook) ? data.rightarrowbook : `${data.rightarrowbook}-${data.rightarrowchapter}`}`
                        + `/verses=${data.rightarrowverses}-jst=${data.rightarrowjst ? 'true' : 'false'}`
    } else {
        leftArrowLink = `/${pathArr[0]}/ct/talk/${data.leftarrowref}`
        rightArrowLink = `/${pathArr[0]}/ct/talk/${data.rightarrowref}`
    }

    return (
        <div id="tpjsnavdivs" className="tpjsnavlinks">
            {data.leftarrow ? (
                <NavLink
                    to={leftArrowLink}
                    className="tpjsleftarrow">
                </NavLink>)
                :
                <></>
            }
            {data.rightarrow ? (
                <NavLink
                    to={rightArrowLink}
                    className="tpjsrightarrow">
                </NavLink>)
                :
                <></>
            }
        </div>
    )
}

export default ContentHeaderArrows;
