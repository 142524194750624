import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import { useFetchLibraryData } from './LibraryAPI';

function JodList(props) {
    const params = useParams();
    const { content, isLoading } = useFetchLibraryData('jodList', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <ul className="jodvolumes">
                    {content.map((volume, idx) => {
                        return(
                            <li className="grid" key={idx}>
                                <NavLink to={`/library/jod/${volume.number}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`} className="waves-effect waves-yellow">
                                    <span className="extendedname">{volume.name}</span>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default JodList;
