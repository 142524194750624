import React, {useRef} from 'react';
import { useHistory } from 'react-router-dom';
import { useFetchCitationFilterData } from "./CitationAPI";
import { useOutsideAlerter } from './ContentHeaderAPI';
import LoadingIndicator from "./LoadingIndicator";

function CitationFilter(props) {
    const {content, isLoading} = useFetchCitationFilterData(true);
    const history = useHistory();
    const filterRef = useRef();
    let filterIndex;

    let pathArr = history.location.pathname.replace("/", "").split('/')
    pathArr.forEach((path, idx) => {
        if (path.includes('ct')) {
            filterIndex = idx - 1;
        }
    });

    const closeFilter = () => {
        props.setShowCitFilter(false);
    }
    const getFilterParameters = () => {
        const speaker = document.getElementById('speakerFilter').value;
        const startYear = document.getElementById('startYearFilter').value;
        const endYear = document.getElementById('endYearFilter').value;
        const corpusfilter = document.getElementById('corpusfilter').value;
        const sortBy = document.getElementById('sortbyfilter').value;
        const groupBy = document.getElementById('groupbyfilter').value;
        pathArr[filterIndex] = `${speaker}-${startYear}-${endYear}-${corpusfilter}-${sortBy}-${groupBy}`;
        history.replace(`/${pathArr.join('/')}`)
        props.setShowCitFilter(false);
    }
    const resetFilterForm = () => {
        document.getElementById('speakerFilter').value = 'Any';
        document.getElementById('startYearFilter').value = content.years[0];
        document.getElementById('endYearFilter').value = content.years[content.years.length - 1];
        document.getElementById('corpusfilter').value = 'all';
        document.getElementById('sortbyfilter').value = 's';
        document.getElementById('groupbyfilter').value = 'f';
    }

    useOutsideAlerter(filterRef, props.setShowCitFilter);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div ref={filterRef}>
            <div className="modal-header">
                <button type="button" className="close" id="closeFilter" onClick={() => closeFilter()} data-target="#filter_dialog" data-toggle="modal">x</button>
                <h5>Filter Citation Index</h5>
            </div>
            <div id="scifilter" className="modal-body">
                <form id="frmFilter">
                    <div className="filterTitle">Speaker</div>
                    <div id="speakerFilterWrapper">
                        <select id="speakerFilter">
                            {content.speakers.map((speaker) => {
                                return <option id={speaker.id} value={speaker.value} key={speaker.id}>{speaker.text}</option>
                            })}
                        </select>
                    </div>
                    <div className="filterTitle">Years</div>
                    <div id="yearsFilter">
                        <select id="startYearFilter" className="input-small" defaultValue={"1830"}>
                            {content.years.map((year) => {
                                return <option id={year} value={year} key={year}>{year}</option>
                            })}
                        </select>
                        <div className="to">To</div>
                        <select id="endYearFilter" className="input-small" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={content.years[content.years.length - 1]}>
                            {content.years.map((year) => {
                                return <option id={year} value={year} key={year}>{year}</option>
                            })}
                        </select>
                    </div>
                    <div className="filterTitle">Content Sources</div>
                    <div className="filterCorpusWrapper">
                        <select id="corpusfilter" defaultValue={'all'}>
                            <option id="all" value="all">All</option>
                            <option id="G" value="G">General Conference</option>
                            <option id="E" value="E">Improvement Era</option>
                            <option id="J" value="J">Journal of Discourses</option>
                            <option id="T" value="T">Teachings of the Prophet Joseph Smith</option>
                        </select>
                    </div>
                    <div className="filterTitle">Sort By</div>
                    <div className="filterSortByWrapper">
                        <select id="sortbyfilter">
                            <option id="scripture" value="s">Scripture</option>
                            <option id="frequency" value="f">Citation Frequency</option>
                        </select>
                    </div>
                    <div className="filterTitle">Group Verses By</div>
                    <div className="filterGroupByWrapper">
                        <select id="groupbyfilter" defaultValue={'f'}>
                            <option id="full" value="f">Full citation</option>
                            <option id="individual" value="i">Individual verse</option>
                        </select>
                    </div>
                </form>
            </div>
            <div className="modal-footer filterButtonWrapper">
                <button id="fitlerBtn" onClick={() => getFilterParameters()} className="btn waves-effect">Filter</button>
                <button id="resetFilter" onClick={() => resetFilterForm()} className="btn waves-effect">Reset</button>
            </div>
        </div>
    )
}

export default CitationFilter;
