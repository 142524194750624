import React from 'react';

function CitationNoResults(props) {
    return (
        <div className="citnoresults">
            <p>{`There are no citations from this ${props.type} that match the current filter.`}</p>
            <br></br>
            <p>Try editing or clearing the filter.</p>
        </div>
    )
}

export default CitationNoResults;
