import React from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { useFetchCitationData } from "./CitationAPI";
import CitationFilterMessage from "./CitationFilterMessage";
import CitationNoResults from "./CitationNoResults";
import LoadingIndicator from "./LoadingIndicator";
import parse from 'html-react-parser';

function CitationChapter(props) {
    const { content, isLoading } = useFetchCitationData('chapter', props.active);
    const params = useParams();

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <CitationFilterMessage filterMessage={content.filterMessage} setShowCitFilter={props.setShowCitFilter}/>
                {!content.verses[0] ?
                    <CitationNoResults type={'chapter'}/>
                    :
                    <ul className="referencesblock">
                        {content.verses.map((reference, idx) => {
                            return(
                                <li key={idx}>
                                    <NavLink
                                        to={`/citation/${params.book}/${params.chapter}/${reference.verses}${reference.jst ? '/jst' : '/reg'}/${params.filter}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`}
                                        className="waves-effect waves-yellow"
                                    >
                                        <div className="reference">{parse(reference.ref)}</div>
                                        <div className="citationcount">{reference.citcount}</div>
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}

export default CitationChapter;
