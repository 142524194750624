import React from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import { useFetchLibraryData } from "./LibraryAPI";
import parse from 'html-react-parser';

function ScriptureBook(props) {
    const params = useParams();
    const neededClassNames = ["", "kjvblock", "jstblock"];

    const { content, isLoading } = useFetchLibraryData('scripBook', props.active);

    if(isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scripturecontent nano has-scrollbar">
            <div className="nano-content" tabIndex="0" style={{ right: "-17px" }}>
                {content.map((cont, idx) => {
                    if (content.length > 1 && idx === 0) {
                        return (
                            <b className="chaptertitle" key={idx}>
                                {parse(cont.title)}
                            </b>
                        );
                    }

                    return (
                        <div className={neededClassNames[idx]} key={idx}>
                            <b className="chaptertitle">
                                {parse(cont.title)}
                            </b>
                            <ul className="chaptersblock">
                                {cont.chapters.map((chapter) => {
                                    return (
                                        <li key={chapter}>
                                            <NavLink
                                                className="waves-effect waves-yellow"
                                                to={`/library/scriptures/${params.volume}/${params.book}/ct/scripture/${params.book}-${chapter}/verses=all-jst=${cont.jst ? 'true' : 'false'}`}
                                            >
                                                {chapter}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ScriptureBook;