import React from 'react';
import {NavLink, useParams} from 'react-router-dom';
import LibraryData from './data/library.json';

function Library() {
    const params = useParams();
    return (
        <div className="scicontent nano">
            <div className="nano-content" tabIndex="0" style={{right: "-17px"}}>
                <ul className="contentlist">
                    {LibraryData.library.map((lib) => (
                        <li className="grid" key={lib.key}>
                            <NavLink
                                to={`${lib.link}ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`}
                                className="waves-effect waves-yellow"
                            >
                                <div className={lib.icon}></div>
                                <div className="contentwrapper">
                                    <div className="topictitle">{lib.title}</div>
                                    <div className="subtitle">{lib.subtitle}</div>
                                </div>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default Library;
