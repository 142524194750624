import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchLibraryData } from "./LibraryAPI";
import parse from 'html-react-parser';
import LoadingIndicator from './LoadingIndicator';

function JodVolume(props) {
    const params = useParams();

    const { content, isLoading } = useFetchLibraryData('jodVolume', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <ul className="discoursesblock">
                    {content.map((discourse, idx) => {
                        return(
                            <li key={idx}>
                                <NavLink
                                    className="waves-effect waves-yellow"
                                    to={`/library/jod/${params.volume}/ct/talk/${discourse.number}`}
                                >
                                    <div className="speaker">{parse(discourse.speaker)}</div>
                                    <div className="talktitle">{discourse.title}</div>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default JodVolume;
