import {useState, useEffect} from 'react';
import { useParams } from "react-router";
import { parseHTML } from './LibraryAPI';

/*----------------------------------------------------------------------
 *                      CONSTANTS
 */
const URL_CITATION_BASE = "https://scriptures.byu.edu/citation_index/";
const URL_CITATION = `${URL_CITATION_BASE}citation_ajax/`;
const URL_FILTER = `${URL_CITATION_BASE}filter_ajax`;

/*----------------------------------------------------------------------
 *                      PRIVATE HELPERS
 */
const isolateCitationBookContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = {};
    returnobject.filterMessage = isolateFilterMessage(htmlstring);

    let titles = htmlstring.querySelector("b.chaptertitle");
    if (!titles) {
        return returnobject;
    }

    returnobject.title = titles.innerHTML.trim();

    let chapterblock = htmlstring.querySelector("ul.chaptersblock");
    let alist = chapterblock.querySelectorAll("li");
    let chapterlist = [];
    alist.forEach((a) => {
        let chapobj = {}
        if(!a.querySelector('a')) {
            chapobj.disabled = true;
        } else {
            chapobj.disabled = false;
        }
        chapobj.chapnum = a.querySelector('div.chap').innerHTML.trim();
        chapobj.citcount = a.querySelector('div.citationcount').innerHTML.trim();

        chapterlist.push(chapobj);
    });
    returnobject.chapters = chapterlist;


    return returnobject;
};

const isolateCitationChapterContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = { verses: [] };
    returnobject.filterMessage = isolateFilterMessage(htmlstring);

    let reflist = htmlstring.querySelector("ul.referencesblock");
    if (!reflist) {
        return returnobject;
    }
    let refs = reflist.querySelectorAll('li');
    refs.forEach(ref => {
        let refobj = {}

        refobj.ref = ref.querySelector('div.reference').innerHTML.trim()
        refobj.citcount = ref.querySelector('div.citationcount').innerHTML.trim()
        const reflinksplit = ref.querySelector('a').getAttribute('onclick').match(/[^()]+/g)[1].match(/[^']+/g);
        refobj.verses = reflinksplit[4].trim();
        refobj.jst = reflinksplit[6] ? true : false;

        returnobject.verses.push(refobj)
    });

    return returnobject;
};

const isolateCitationFilterContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = {};

    returnobject.speakers = [];
    const speakerSelect = htmlstring.querySelector('#speakerFilter');
    const speakers = speakerSelect.querySelectorAll('option');
    speakers.forEach((speaker) => {
        let speakerObj = {};

        speakerObj.id = speaker.id;
        speakerObj.value = speaker.value;
        speakerObj.text = speaker.innerHTML.trim();
        returnobject.speakers.push(speakerObj);
    });

    returnobject.years = [];
    const yearSelect = htmlstring.querySelector('#startYearFilter');
    const years = yearSelect.querySelectorAll('option');
    years.forEach((year) => {
        returnobject.years.push(year.id);
    });

    return returnobject;
}

const isolateCitationReferenceContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = {};
    returnobject.filterMessage = isolateFilterMessage(htmlstring);

    let refslist = htmlstring.querySelector("ul.referencesblock");
    if (!refslist) {
        return returnobject;
    }

    let title = htmlstring.querySelector('div.volumetitle').innerHTML.match(/[^<>]+/g);
    if (title.length < 3) {
        returnobject.title = title[0].trim();
    } else if (title.length < 6) {
        returnobject.title = (title[0] + title[2]).trim();
    } else {
        returnobject.title = (title[0] + title[2] + title[4]).trim();
    }
    returnobject.refs = [];

    let refs = refslist.querySelectorAll("li");
    refs.forEach((ref) => {
        let refobj = {};

        refobj.reference = ref.querySelector('div.reference').innerHTML.trim();
        refobj.talktitle = ref.querySelector('div.talktitle').innerHTML.trim();

        let alist = ref.querySelectorAll('a');
        alist.forEach((a, idx) => {
            let click = a.getAttribute('onclick').split("'");
            if(idx === 0) {
                refobj.talknum = click[1];
                refobj.refnum = click[3];
            }
            else if(idx === 1) {
                refobj.watchlink = click[3];
            }
            else if(idx === 2) {
                refobj.listenlink = click[3];
            }
        })
        if(ref.querySelector('div.watchtalk')){
            refobj.media = true;
        } else {
            refobj.media = false;
        }

        returnobject.refs.push(refobj);
    });

    return returnobject;
};

const isolateCitationVolumeContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = { volumes: [] };
    returnobject.filterMessage = isolateFilterMessage(htmlstring);

    let titles = htmlstring.querySelectorAll("div.volumetitle");
    titles.forEach((title) =>
        returnobject.volumes.push({
            title: title.innerHTML.split('<')[0].trim(),
            volcitcount: title.querySelector('span.vol_citation').innerHTML.trim()
        })
    );

    let volumelist = htmlstring.querySelectorAll("ul.volumecontents");
    volumelist.forEach((lists, idx) => {
        let alist = lists.querySelectorAll("a");
        let booklist = [];
        alist.forEach(a => {
            let bookobj ={}
            bookobj.name = a.querySelector("div.book").innerHTML.trim();
            bookobj.citationcount = a.querySelector("div.citationcount").innerHTML.trim();
            let aclick = a.getAttribute('onclick').split("'");
            bookobj.bookid = aclick[1];
            if (aclick.length > 3) {
                bookobj.gotoref = true;
            } else {
                bookobj.gotoref = false;
            }

            booklist.push(bookobj);
        });

        returnobject.volumes[idx]["books"] = booklist;
    });

    return returnobject;
};

const isolateFilterMessage = (html) => {
    let filterMessage = [];

    const message = html.querySelector('div.filter_message');
    if (message) {
        let filterLines = message.querySelectorAll('span');
        filterLines.forEach((line) => {
            filterMessage.push(line.innerHTML.trim());
        });
    }

    return filterMessage;
}


/*----------------------------------------------------------------------
 *                      PUBLIC HOOKS
 */

function useFetchCitationData(component, active) {
    const [content, setContent] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [curTitle, setCurTitle] = useState();
    const params = useParams();
    const filter = params.filter ? params.filter.replaceAll("-", "/") : null;

    let fetchstring;
    if (component === 'reference' && params.chapter === '0') {
        fetchstring = '?verses=';
    } else {
        fetchstring = `/${params.chapter}?verses=${params.verses}${params.jst === 'jst' ? '&jst=JST' : ''}`;
    }

    useEffect(() => {
        const query = {
            volume: `${URL_CITATION}${filter}`,
            book: `${URL_CITATION}${filter}/${params.book}`,
            chapter: `${URL_CITATION}${filter}/${params.book}/${params.chapter}`,
            reference: `${URL_CITATION}${filter}/${params.book}${fetchstring}`
        };
        if (active) {
            fetch(query[component])
                .then((response) => response.text())
                .then((response) => {

                    if (component === 'volume') {
                        response = isolateCitationVolumeContent(response);
                    }
                    if (component === 'book') {
                        response = isolateCitationBookContent(response);
                    }
                    if (component === 'chapter') {
                        response = isolateCitationChapterContent(response);
                    }
                    if (component === 'reference') {
                        response = isolateCitationReferenceContent(response);
                    }

                    if (response.title) {
                        setCurTitle(response.title);
                    }
                    response.currentTitle = curTitle;

                    setContent(response);
                    setIsLoading(false);
                }
            );
        }
    }, [active, component, params.book, params.chapter, fetchstring, filter, curTitle]);

    return {content, isLoading}
}

function useFetchCitationFilterData() {
    const [content, setContent] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetch(URL_FILTER)
            .then((response) => response.text())
            .then((response) => {
                response = isolateCitationFilterContent(response);

                setContent(response);
                setIsLoading(false);
            }
        );
    }, [])

    return {content, isLoading}
}

export { useFetchCitationData, useFetchCitationFilterData };