import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

const LETTERS = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

function SpeakersTab() {
    const params = useParams();

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <ul className="speakerletters">
                    {LETTERS.map((letter, idx) => {
                        return(
                            <li key={idx}>
                                <NavLink
                                    to={`/speakers/${letter}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`}
                                    className="oneline waves-effect waves-yellow"
                                >
                                    <div className="speakerletter">{letter}</div>
                                    <div className="arrow"></div>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default SpeakersTab;
