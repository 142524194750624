import React from 'react';
import { NavLink } from 'react-router-dom';

function ContentHeaderLabel(props) {
    const data = props.data;
    const pathArr = props.pathArr;

    return (
        <div id={data.titleId} className={data.titleClass}>
            {data.titlelist.map((link, idx1) => {
                if (link.spanlist && link.spanlist.length > 0) {
                    return(
                        <NavLink to={`${link.navlink}/ct${pathArr[1]}`}  key={idx1}>
                            {link.spanlist.map((span, idx2) => {
                                return <span className={span.class} key={idx2}>{span.text}</span>
                            })}
                            <span key={link.spanlist.length + 3}>{link.text}</span>
                        </NavLink>
                    )

                }
                return <NavLink to={`${link.navlink}/ct${pathArr[1]}`} key={idx1}>{idx1 < 1 ? `${link.text} ` : link.text}</NavLink>;
            })}
            {data.titleText}
        </div>
    )
}

export default ContentHeaderLabel;
