import React from 'react';
import SearchResultsScroller from './SearchResultsScroller';
import parse from 'html-react-parser';
import { useFetchSearchData } from './SearchAPI';
import LoadingIndicator from './LoadingIndicator';

function SearchResults(props) {
    const { content, isLoading, type } = useFetchSearchData('searchResults', props.active)

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div id="scisearch" className="nano-content">
                <div className="qSummary">
                    {parse(content.summary)}
                </div>
                {content.totalresults === 0 ?
                    <></>
                    :
                    <SearchResultsScroller type={type}/>
                }
                {/* {content.pagination.status === 'None' ?
                    <></>
                    :
                    <div className="pagination pagination-small resultsNav">
                        <ul>
                            {content.pagination.nodes.map((node, idx) => {
                                return(
                                    <li className={node.class} key={idx}>
                                        {node.class === 'active' ?
                                            <span className={node.innerClass}>{node.text}</span>
                                            :
                                            <NavLink to={`/search/results/${node.toResNum}`} className={node.innerClass}>{node.text}</NavLink>
                                        }
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                } */}
            </div>
        </div>
    )
}

export default SearchResults;
