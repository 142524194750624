import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { useFetchCitationData } from "./CitationAPI";
import CitationFilterMessage from "./CitationFilterMessage";
import LoadingIndicator from "./LoadingIndicator";

const ONE_CHAP_BOOKS = ['Obadiah', 'Philem.', '2 John', '3 John', 'Jude', 'Enos', 'Jarom', 'Omni', 'W of M', '4 Nephi', 'JS—M', 'JS—H', 'A of F'];

// SCIcitationbook (Shows Chapters)
// SCIcitationchapter (Shows references)
// SCIcitationreference (Shows attached content)

// Some jump straight to Chapter View (Obadiah, Philemon, 2 & 3 John, Jude, Enos, Jarom, Omni, WOM, 4 Nehpi, JS Matthew, JS Histroy, AoF)
// Some jump straight to refence view (BOM Title, Intro, 3 Wit, 8 Wit, D&C Intro)

function CitationVolumes(props) {
    const params = useParams();
    const {content, isLoading } = useFetchCitationData('volume', props.active);

    const buildNavString = (book) => {
        let navString;
        if (book.gotoref) {
            navString = `/citation/${book.bookid}/0/0/reg`;
        }
        else if (ONE_CHAP_BOOKS.includes(book.name)){
            navString = `/citation/${book.bookid}/1`;
        }
        else {
            navString = `/citation/${book.bookid}`
        }

        return navString;
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <CitationFilterMessage filterMessage={content.filterMessage} setShowCitFilter={props.setShowCitFilter}/>
                {content.volumes.map((volume, outidx) => {
                    return (
                        <div key={outidx}>
                            <div className="volumetitle">
                                {volume.title}
                                <span className="vol_citation">{volume.volcitcount}</span>
                            </div>
                            <ul className="volumecontents">
                                {volume.books.map((book, inidx) => {
                                    let navString = buildNavString(book);
                                    return (
                                        <li className="grid" key={inidx}>
                                            <NavLink
                                                to={`${navString}/${params.filter}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`}
                                                className="waves-effect waves-yellow"
                                            >
                                                <div className="book">{book.name}</div>
                                                <div className="citationcount">{book.citationcount}</div>
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default CitationVolumes;
