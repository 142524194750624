import React from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { useFetchCitationData } from "./CitationAPI";
import LoadingIndicator from "./LoadingIndicator";
import parse from 'html-react-parser';
import CitationFilterMessage from "./CitationFilterMessage";
import CitationNoResults from "./CitationNoResults";

function CitationBook(props) {
    const {content, isLoading} = useFetchCitationData('book', props.active);
    const params = useParams();

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <CitationFilterMessage filterMessage={content.filterMessage} setShowCitFilter={props.setShowCitFilter}/>
                <b className="chaptertitle">
                    {content.title ? parse(content.title) : (content.currentTitle ? parse(content.currentTitle) : null)}
                </b>
                {!content.chapters ?
                    <CitationNoResults type={'book'}/>
                    :
                    <ul className="chaptersblock">
                        {content.chapters.map((chapter, idx) => {
                            if (chapter.disabled) {
                                return(
                                    <li className="disabled" key={idx}>
                                        <div className="disabledwrapper waves-effect">
                                            <div className="chap">{chapter.chapnum}</div>
                                            <div className="citationcount">{chapter.citcount}</div>
                                        </div>
                                    </li>
                                )
                            }

                            return(
                                <li key={idx}>
                                    <NavLink
                                        to={`/citation/${params.book}/${chapter.chapnum}/${params.filter}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`}
                                        className="waves-effect waves-yellow"
                                    >
                                        <div className="chap">{chapter.chapnum}</div>
                                        <div className="citationcount">{chapter.citcount}</div>
                                    </NavLink>
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}

export default CitationBook;
