import React, {useState} from 'react';
import { HashRouter as Router } from 'react-router-dom';
import AppData from './AppData';
import Content from './Content';
import Navigator from './Navigator';
import './Waves.js';
import './css/About.css';
import './css/bootstrap-responsive.css';
import './css/bootstrap.css';
import './css/citation_print.css';
import './css/citation.css';
import './css/gcen.css';
import './css/gcera.css';
import './css/jod.css';
import './css/mapscrip.css';
import './css/nanoscroller.css';
import './css/stpjs.css';
import './css/transition.css';

function App() {
    const [data, setData] = useState({
        isLoading: true,
    });

    return (
        <Router>
            <AppData.Provider value={{ data, setData }}>
                <main>
                    <div className="body_content">
                        <div className="mainpagecontent">
                            <div id="scriptures" className="sidecolumn" >
                                <Navigator />
                            </div>
                            <div id="centercolumn" >
                                <Content />
                            </div>
                        </div>
                    </div>
                </main>
            </AppData.Provider>
        </Router>
    );
}

export default App;
