import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import { useFetchLibraryData } from './LibraryAPI';
import parse from 'html-react-parser';


function TopicalIndex(props) {
    const params = useParams();
    const { content, isLoading } = useFetchLibraryData('topIdx', props.active, props.setCrumbTextArr);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <ul className="topiclist">
                    {content.map((topic, idx) => {
                        let idString;
                        let contentString;
                        if (topic.arrow) {
                            if (params.id) {
                                idString = `${params.id}-${topic.nexttop}`;
                            } else {
                                idString = topic.nexttop;
                            }
                            contentString = `${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`
                        } else {
                            idString = params.id
                            contentString = `talk/${topic.nexttop}/citematch=${topic.nexttopciteid}`;
                        }

                        return (
                            <li className="grid" key={idx}>
                                <NavLink
                                    // to={`/library/topidx/${topic.arrow ? topic.nexttop : `${params.id}/${topic.nexttop}`}`}
                                    to={`/library/topidx/${idString}/ct/${contentString}`}
                                    className={`waves-effect waves-yellow${topic.arrow ? " oneline" : ""}`}
                                >
                                    <div className="topictitle">{parse(topic.title)}</div>
                                    <div className="topicsubtitle">{parse(topic.subtitle)}</div>
                                    <div className={topic.arrow ? "arrow" : ""}></div>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>

    )
}

export default TopicalIndex;
