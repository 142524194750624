import React from 'react';
import {Redirect} from 'react-router-dom';

function RedirectBase() {
    return (
        <Redirect path="/" to="/citation/Any-1830-2021-all-s-f/ct/talk/about" />
    )
}

export default RedirectBase;
