import React from 'react';
import {useLocation, NavLink} from 'react-router-dom';
import CitationFilter from './CitationFilter';
import books from './data/books.json';
import volumes from './data/volumes.json';
import speakers from './data/speakers.json';
import parse from 'html-react-parser';

const ONE_CHAP_BOOKS_NUM = ['131', '157', '163', '164', '165', '208', '209', '210', '211', '216', '404', '405', '406'];

function NavHeader(props) {
    const setShowCitFilter = props.setShowCitFilter;
    const showCitFilter = props.showCitFilter;
    const crumbTextArr = props.crumbTextArr;

    const location = useLocation();
    const pathArr = location.pathname.substring(1).split('/');
    let pathnames = pathArr.slice(0, pathArr.indexOf('ct'));
    let pathContent;

    if (pathnames.length > 0 && pathnames[0] === 'citation') {
        pathnames.pop()
        pathContent = pathArr.slice(pathArr.indexOf('ct') - 1);
    } else {
        pathContent = pathArr.slice(pathArr.indexOf('ct'));
    }

    if (pathnames[1] === 'genconf') {
        if (pathnames.length > 3) {
            pathnames[2] = parse(`${pathnames[2]}&ndash;${pathnames[3]}`);
            pathnames = pathnames.slice(0, 3);
        }
    };

    if (pathnames[1] === 'jod') {
        if (pathnames.length > 2) {
            pathnames[2] = pathnames[2].charAt(0) === '0' ? pathnames[2].slice(1) : pathnames[2];
        }
    };

    let trimmedPath = null;
    if (pathnames[1] === 'topidx') {
        if (pathnames.length > 2) {
            pathnames = [pathnames[0], pathnames[1], ...pathnames[2].split('-')]
        }
        if (pathnames.length > 4) {
            trimmedPath = pathnames.splice(2, pathnames.length - 4);
            pathnames.splice(2, pathnames.length - 4);
        }
    }

    if (pathnames[1] === 'scripteach') {
        if (pathnames.length > 2) {
            const regex = new RegExp("^0+(?!$)",'g');
            pathnames[2] = pathnames[2].replace('27', '').replaceAll(regex, '');
        }
    };

    if (pathnames[1] === 'results') {
        // remove the search criteria from the crumb
        pathnames.pop()
    }

    // Fixes for breadcrumb in the Citation Index
    if (pathnames[2] === '0') {
        // Fix the path if you skip from volume view straight to references
        pathnames = pathnames.slice(0, 2);
    } else if (pathnames[4] === 'reg') {
        // Trim the excess parameters from pathnames
        pathnames = pathnames.slice(0, 4);
    } else if (pathnames[4] === 'jst') {
        // Trim excess params and add JST annotation to the text
        pathnames[3] = `${pathnames[3]} (JST)`;
        pathnames = pathnames.slice(0, 4);
    };

    if (!pathnames[pathnames.length - 1] || pathnames[pathnames.length - 1] === "") {
        // Trim blank params from the path
        pathnames.pop();
    };

    const capFirstLetter = (string) => {
        // Capitalizes the first letter
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const toggleShowCitFilter = (current) => {
        current ? setShowCitFilter(false) : setShowCitFilter(true);
    };

    const convertPathtoCrumbText = (path, idx) => {
        let crumbText = path;
        switch (pathnames[0]) {
            case 'citation':
                crumbText = idx === 0 ? 'Citation Index' : crumbText;
                // Name of Book
                crumbText = idx === 1 && books[path] ? parse(books[path].fullName) : crumbText;
                break;
            case 'library':
                switch (pathnames[1]) {
                    case 'scriptures':
                        // Name of volume
                        crumbText = idx === 2 ? parse(volumes[path - 1].citeAbbr) : crumbText;
                        // Name of Book
                        crumbText = idx === 3 && books[path] ? parse(books[path].fullName) : crumbText;
                        break;
                    case 'genconf':
                        crumbText = idx === 1 ? 'General Conference' : crumbText;
                        break;
                    case 'jod':
                        crumbText = idx === 1 ? 'Journal of Discourses' : crumbText;
                        break;
                    case 'topidx':
                        crumbText = idx === 1 ? 'Topical Index' : crumbText;
                        crumbText = idx > 1 ? (crumbTextArr[idx - 1] ? parse(crumbTextArr[idx - 1]) : crumbText) : crumbText;
                        break;
                    case 'scripteach':
                        crumbText = idx === 1 ? 'Scriptural Teachings of the Prophet JS' : crumbText;
                        break;
                    default:
                        break;
                }
                break;
            case 'speakers':
                crumbText = idx === 2 ? parse(speakers[path].encodedName) : crumbText;
                break;
            default:
                crumbText = path;
        };

        return crumbText;
    };

    const setPathForCrumb = (idx) => {
        let toPath = '';
        for(let i=0; i <= idx; i+=1) {
            // Handle path if it jumps straight to the chapter content
            if (idx === 1 && ONE_CHAP_BOOKS_NUM.includes(pathnames[i])) {
                toPath += `${pathnames[i]}/1/`;
            } else if (idx > 1 && pathnames[1] === 'topidx') {
                // Adjust path for additional Topical Index sections
                let tempPathArr = pathnames.slice(2, idx + 1)
                toPath = `library/topidx/${trimmedPath ? `${trimmedPath.join('-')}-` : ''}${tempPathArr.join('-')}/`;
            } else {
                toPath += `${pathnames[i]}/`;
            }
        }

        return toPath;
    }

    return (
        <>
            <ul className="crumb">
                {pathnames.map((path, idx) => {
                    const pathText = convertPathtoCrumbText(path, idx);

                    let toPath = setPathForCrumb(idx);

                    if (idx === pathnames.length - 1) {
                        return(
                            <li className="lastcrumb" key={idx}>
                                <span>{capFirstLetter(pathText)}</span>
                            </li>
                        )
                    }

                    return(
                        <li className="acrumb" key={idx}>
                            <NavLink to={`/${toPath}${pathContent.join('/')}`}>
                                <span className="largecrumb">{capFirstLetter(pathText)}</span>
                            </NavLink>
                        </li>
                    )
                })}
            </ul>
            {pathnames[0] === '' || pathnames[0] === 'citation' ?
                <button
                    className="resources open_filter_btn"
                    title="Filter"
                    onClick={() => toggleShowCitFilter(showCitFilter)}
                    data-target="#filter_dialog"
                    data-toggle="modal"
                ></button>
                :
                <></>
            }
            <div id="filter_dialog"
                className={`modal ${showCitFilter ? 'filter_shown in' : ''}`}
                aria-hidden={showCitFilter}
                style={{display: showCitFilter ? 'block' : 'none'}}
            >
                <CitationFilter setShowCitFilter={setShowCitFilter}/>
            </div>
            {showCitFilter ?
                <div className="modal-backdrop in"></div>
                :
                <></>
            }
        </>
    )
}

export default NavHeader;
