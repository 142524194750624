import React from 'react';
import { useHistory } from 'react-router-dom';

function SearchTalk(props) {
    const history = useHistory();
    const years = props.content.years;

    const path = history.location.pathname.split('/');
    const pathContent = path.slice(path.indexOf('ct')).join('/');

    const searchTalks = () => {
        let error = false;

        let terms = props.getSearchText();
        if (!terms) {
            error = true;
        }

        let speaker = document.getElementById('speakerSearch').value;
        let speakerName = speaker.split('-');
        speaker = speaker.split('-')[0];
        speaker = speaker === 'Any' ? '' : speaker;

        let sYear = document.getElementById('startYearSearch').value;
        let eYear = document.getElementById('endYearSearch').value;

        let chkGenConf = document.getElementById('genConf').checked;
        let chkJoD = document.getElementById('jod').checked;
        let chkStpjs = document.getElementById('stpjs').checked;

        let chosenCorpus = '';

        if (chkGenConf) {
            chosenCorpus += 'g';
        }
        if (chkJoD) {
            chosenCorpus += 'j';
        }
        if (chkStpjs) {
            chosenCorpus += 't';
        }
        if (chosenCorpus === '') {
            // Default to general conference talks.
            chosenCorpus = 'g';
        }

        let chkTalkRelevance = document.getElementById('talksSortBy').checked;
        let talksSortBy = '';
        if (chkTalkRelevance) {
            talksSortBy = 'r';
        } else {
            talksSortBy = 'n';
        }

        let talkArr = ['t', speaker, sYear, eYear, chosenCorpus, talksSortBy, '30@'];

        if (speakerName.length > 1) {
            speakerName = speakerName[2].split('(')[0].trim();
        } else {
            speakerName = speakerName[0].trim();
        }

        if (!error) {
            history.push(`/search/results/${talkArr.join('&')}-${encodeURIComponent(terms)}-${encodeURIComponent(speakerName)}/${pathContent}`);
        } else {
            alert("Text box is empty");
        }
    }

    const resetTalksForm = () => {
        props.resetSearchText();
        document.getElementById('speakerSearch').value = 'Any';
        document.getElementById('startYearSearch').value = years[0];
        document.getElementById('endYearSearch').value = years[years.length - 1];
        document.getElementById('genConf').checked = true;
        document.getElementById('jod').checked = true;
        document.getElementById('stpjs').checked = true;
        document.getElementById('talksSortBy').checked = true;
    }

    return (
        <div id="searchTalksWrapper" className={props.display ? "" : "hidden"}>
            <form id="searchTalks" onSubmit={() => searchTalks()}>
                <div className="advTitle">Speakers</div>
                <div id="speakerSearchWrapper">
                    <select id="speakerSearch" title="Which speaker(s) should be included in your search?">
                        {props.content.speakers.map((speaker, idx) => {
                            return(
                                <option id={speaker.id} value={speaker.value} key={idx}>{speaker.text}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="advTitle">Years</div>
                <div id="yearsSearch" title="Search talks between these years">
                    <select id="startYearSearch" className="input-small">
                        {years.map((year, idx) => {
                            return(
                                <option id={`s${year}`} value={year} key={idx}>{year}</option>
                            )
                        })}
                    </select>
                    <div className="to">To</div>
                    <select id="endYearSearch" className="input-small" key={`${Math.floor((Math.random() * 1000))}-min`} defaultValue={years[years.length - 1]}>
                        {years.map((year, idx) => {
                            return(
                                <option id={`e${year}`} value={year} key={idx}>{year}</option>
                            )
                        })}
                    </select>
                </div>
                <div className="advTitle">Content Source</div>
                <div className="advTalks">
                    {props.content.labels.map((label, idx) => {
                        return(
                            <label className="checkbox" title={label.title} key={idx}>
                                <input id={label.inputid} type="checkbox" defaultChecked />
                                {label.text}
                            </label>
                        )
                    })}
                </div>
            </form>
            <div className="searchBtnWrapper">
                <button id="talkSearchBtn" onClick={() => searchTalks()} title="Start the search" className="btn searchbutton waves-effect">Search</button>
                <button title="Reset search form to defaults" id="resetTalksForm" onClick={() => resetTalksForm()} className="btn waves-effect">Reset</button>
            </div>
        </div>
    )
}

export default SearchTalk;
