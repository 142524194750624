import React from 'react';
import { useHistory } from 'react-router-dom';


function CitationFilterMessage(props) {
    const history = useHistory();
    let pathArr = history.location.pathname.replace("/", "").split('/')
    let filterIndex;
    // Find index of filter in path
    pathArr.forEach((path, idx) => {
        if (path.includes('ct')) {
            filterIndex = idx - 1;
        }
    });
    // Reset the filter
    pathArr[filterIndex] = 'Any-1830-2021-all-s-f';

    if (props.filterMessage.length < 1) {
        return <></>;
    }

    return (
        <div className="filter_message">
            <b>The citation index is filtered as follows:</b>
            {props.filterMessage.map((message, idx) => {
                return <span className="filter_line" key={idx}>{message}</span>
            })}
            <div className="filter_btn">
                <button onClick={() => props.setShowCitFilter(true)} className="btn clearFilterButton waves-effect">Edit</button>
                <button onClick={() => history.replace(`/${pathArr.join('/')}`)} className="btn clearFilterButton waves-effect">Clear</button>
            </div>
        </div>
    )
}

export default CitationFilterMessage;
