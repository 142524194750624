import React from 'react';
import {NavLink, useParams} from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import { useFetchLibraryData } from './LibraryAPI';

function ConferenceTalks(props) {
    const params = useParams();

    const { content, isLoading } = useFetchLibraryData('confTalks', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                {content.map((session, idx) => {
                    return(
                        <div key={idx}>
                            <div className="sessiontitle">{session.title}</div>
                            <ul className="talksblock">
                                {session.talks.map((talk, idx) => {
                                    return(
                                        <li key={idx}>
                                            <NavLink
                                                className="waves-effect waves-yellow"
                                                to={`/library/genconf/${params.year}/${params.month}/ct/talk/${talk.talknum}`}
                                            >
                                                <div className="speaker">{talk.speaker}</div>
                                                <div className={`talktitle ${talk.media ? 'talktitlewatch talktitlelisten' : ''}`}>{talk.title}</div>
                                            </NavLink>
                                            {!talk.media ?
                                                <></>
                                                :
                                                <>
                                                <a href={talk.watchlink} target="_blank" rel="noreferrer" className="watchlink" title="Watch talk at lds.org">
                                                    <div className="watchtalk">Watch</div>
                                                </a>
                                                <a href={talk.listenlink} target="_blank" rel="noreferrer" className="listenlink" title="Listen to talk at lds.org" >
                                                    <div className="listentalk">Listen</div>
                                                </a>
                                                </>
                                            }
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}

export default ConferenceTalks;
