import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import { useFetchLibraryData } from './LibraryAPI';

function ConferenceList(props) {
    const params = useParams();
    const { content, isLoading } = useFetchLibraryData('confList', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <ul className="conflist">
                    {content.map((session, idx) => {
                        return(
                            <li key={idx}>
                                <NavLink to={`/library/genconf/${session.year}/${session.month}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`} className="waves-effect waves-yellow">
                                    {session.name}
                                    <span className="extendedname">{session.extname}</span>
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default ConferenceList;
