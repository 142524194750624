import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { useFetchCitationData } from './CitationAPI';
import CitationFilterMessage from './CitationFilterMessage';
import CitationNoResults from './CitationNoResults';
import LoadingIndicator from './LoadingIndicator';

function CitationReference(props) {
    const params = useParams();
    const { content, isLoading } = useFetchCitationData('reference', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <CitationFilterMessage filterMessage={content.filterMessage} setShowCitFilter={props.setShowCitFilter}/>
                <div className="volumetitle">{content.title ? content.title : content.currentTitle}</div>
                {!content.refs ?
                    <CitationNoResults type={'reference'}/>
                    :
                    <ul className="referencesblock">
                        {content.refs.map((ref, idx) => {
                            return(
                                <li key={idx}>
                                    <NavLink
                                        className="refcounter waves-effect waves-yellow"
                                        to={`/citation/${params.book}/${params.chapter}/${params.verses}/${params.jst}/${params.filter}/ct/talk/${ref.talknum}${ref.refnum ? `/citematch=${ref.refnum}` : ''}`}
                                    >
                                        <div className="reference referencewatch referencelisten">{ref.reference}</div>
                                        <div className="talktitle talktitlewatch talktitlelisten">{ref.talktitle}</div>
                                    </NavLink>
                                    {!ref.media ?
                                        <></>
                                        :
                                        <>
                                        <a href={ref.watchlink} target="_blank" rel="noreferrer" className="watchlink" title="Watch talk at lds.org">
                                            <div className="watchtalk">Watch</div>
                                        </a>
                                        <a href={ref.listenlink} target="_blank" rel="noreferrer" className="listenlink" title="Listen to talk at lds.org" >
                                            <div className="listentalk">Listen</div>
                                        </a>
                                        </>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                }
            </div>
        </div>
    )
}

export default CitationReference;
