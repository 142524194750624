import { useEffect, useState } from 'react';
import { parseHTML } from './LibraryAPI';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import books from './data/books.json';

/*----------------------------------------------------------------------
 *                      CONSTANTS
 */
const URL_BASE = "https://scriptures.byu.edu/";
const URL_CHURCH_SITE = "https://lds.org/";
const URL_TALKS_AJAX = `${URL_BASE}content/talks_ajax/`;
const URL_SCRIPTURES = `${URL_BASE}scriptures/scriptures_ajax/`;
const URL_GS = `${URL_BASE}scriptures/sync_scrip/`;
const URL_SX = `${URL_BASE}citation_index/sync_sci/`;
const STATIC_URL_PREFIX = `http://${window.location.host}/`;
const SECURE_STATIC_URL_PREFIX = `https://${window.location.host}/`;
const CITE_CLASS = "citation citematch";

/*----------------------------------------------------------------------
 *                      PRIVATE HELPERS
 */
const isolateScriptureContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);

    let content = htmlstring.querySelector("div.scripturecontent");
    if (content) {
        return content.innerHTML;
    }
};

const fixImgLinks = (htmlstring) => {
    htmlstring = parseHTML(htmlstring);
    let imgs = htmlstring.querySelectorAll('img')
    imgs.forEach((img) => {
        if (!img.src.includes(URL_CHURCH_SITE)) {
            if (img.src.includes(STATIC_URL_PREFIX)) {
                img.src = img.src.replace(STATIC_URL_PREFIX, URL_BASE);
            } else if (img.src.includes(SECURE_STATIC_URL_PREFIX)) {
                img.src = img.src.replace(SECURE_STATIC_URL_PREFIX, URL_BASE);
            } else {
                img.src = `${URL_BASE}${img.src}`;
            }
        }
    })
    return htmlstring.body.innerHTML;
}

const fixPDFLinks = (htmlstring) => {
    htmlstring = parseHTML(htmlstring);
    let pdfs = htmlstring.querySelectorAll('div.break')
    pdfs.forEach((pdf) => {
        let pdflink = pdf.querySelector('a');
        pdflink.href = pdflink.href.replace(STATIC_URL_PREFIX, URL_BASE);
    })
    return htmlstring.body.innerHTML;
}

const fixReferenceLinks = (htmlstring) => {
    htmlstring = parseHTML(htmlstring);
    let citematches = htmlstring.querySelectorAll('span.citation');
    citematches.forEach((citematch) => {
        let links = citematch.querySelectorAll('a');
        links.forEach((link, idx) => {
            link.setAttribute('onclick', null);
            link.setAttribute('id', idx === 0 ? 'sx' : 'gs')
        })
    });

    return htmlstring.body.innerHTML;
}

const insertCiteMatch = (htmlstring, match) => {
    htmlstring = parseHTML(htmlstring);
    let span = htmlstring.getElementById(`${match}`);
    if (span) {
        span.className = CITE_CLASS;
    }

    return htmlstring.body.innerHTML;
}

const isolateTalkHeader = (htmlstring, setHeaderHtml) => {
    htmlstring = parseHTML(htmlstring);
    let headObj = {listitems: [], titlelist: []}
    const navbar = htmlstring.querySelector('#centernavbar');

    if (navbar) {
        // Isolate label
        const talklabel = navbar.querySelector('div.visiblelabel');
        headObj.titleId = talklabel.id;
        headObj.titleClass = talklabel.className;
        headObj.titleText = talklabel.lastChild.nodeValue;
        let titlearr = talklabel.querySelectorAll('a')
        if (titlearr.length > 0) {
            titlearr.forEach((a => {
                let aitem = {spanlist: []};

                const onclick = a.getAttribute('onclick').split("'");
                if (onclick[0] === 'getConf(') {
                    aitem.navlink = `/library/genconf/${onclick[1]}/${onclick[3]}`;
                } else if (onclick[0] === 'getJod();') {
                    aitem.navlink = '/library/jod';
                } else if (onclick[0] === 'getJod(') {
                    aitem.navlink = `/library/jod/${onclick[1]}`;
                } else if (onclick[0] === 'getTPJS()') {
                    aitem.navlink = '/library/scripteach';
                } else {
                    aitem.navlink = '/library';
                }

                if (a.querySelector('span')) {
                    a.querySelectorAll('span').forEach((s) => {
                        let spanobj = {};
                        spanobj.class = s.className;
                        spanobj.text = s.innerHTML;
                        aitem.spanlist.push(spanobj);
                    })
                    aitem.text = '';
                } else {
                    aitem.text = a.innerHTML;
                }
                headObj.titlelist.push(aitem)
            }))
        }

        // Isolate Print Button
        const printbutton = navbar.querySelector('a.print_button');
        headObj.print = printbutton ? true : false;

        // Isolate Arrows
        const arrows = navbar.querySelector('div.tpjsnavlinks');
        if (arrows) {
            if (arrows.querySelector('a.tpjsleftarrow')) {
                headObj.leftarrow = true;
                headObj.leftarrowref = arrows.querySelector('a.tpjsleftarrow').getAttribute('onclick').split("'")[1]
            } else {
                headObj.leftarrow = false;
                headObj.leftarrowref = '';
            }
            if (arrows.querySelector('a.tpjsrightarrow')) {
                headObj.rightarrow = true;
                headObj.rightarrowref = arrows.querySelector('a.tpjsrightarrow').getAttribute('onclick').split("'")[1]
            } else {
                headObj.rightarrow = false;
                headObj.rightarrowref = '';
            }
        }


        // Isolate Dropdown list
        const dropa = navbar.querySelector('a.menu');
        headObj.dropdownId = dropa.id ? dropa.id : '';
        headObj.dropdownClass = dropa.className ? dropa.className : '';
        headObj.dropdownDatatoggle = dropa.getAttribute('data-toggle') ? dropa.getAttribute('data-toggle') : '';

        const dropul = navbar.querySelector('ul.dropdown-menu');
        headObj.ulClass = dropul ? dropul.className : '';
        headObj.ulRole = dropul ? dropul.getAttribute('role') : '';
        headObj.ulLabelledby = dropul ? dropul.getAttribute('aria-labelledby') : '';

        const headlist = dropul.querySelectorAll('li');
        headlist.forEach((listitem) => {
            let listObj = {}
            const a = listitem.querySelector('a');
            listObj.id = a.id ? a.id : '';
            listObj.text = a.innerHTML.trim();
            listObj.style = a.getAttribute('style') ? a.getAttribute('style') : '';

            headObj.listitems.push(listObj);
        });
    }

    setHeaderHtml(headObj);
    if (htmlstring.getElementById('centercontent')) {
        return htmlstring.getElementById('centercontent').innerHTML
    }
}

const isolateScriptureHeader = (htmlstring, setHeaderHtml) => {
    htmlstring = parseHTML(htmlstring);
    let headObj = {listitems: [], titlelist: []}

    // Construct Title Text
    const navul = htmlstring.querySelector('ul.crumb');
    if (navul) {
        const navlist = navul.querySelectorAll('li');
        let jst = false;

        headObj.titlelist.push({text: "Scriptures: ", navlink: '/library/scriptures'})

        navlist.forEach((li, idx) => {
            let aitem = {}

            if (li.className === 'acrumb' && idx === navlist.length - 2) {
                const a = li.querySelector('a');
                const click = a.getAttribute('onclick').split('&');
                if (click[3] === "jst=JST')") {
                    jst = true;
                }
                const book = click[0].split('=')[1];
                if (click.length < 2) {
                    aitem.navlink = '/library/scriptures';
                } else {
                    aitem.navlink = `/library/scriptures/0/${book}`;
                }
                if (books[book]) {
                    aitem.text = `${parse(books[book].subdiv.trim())} `;
                } else {
                    aitem.text = `${parse(a.querySelector('span.largecrumb').innerHTML.trim())} `;
                }

                headObj.titlelist.push(aitem)
            }
            if (li.className === 'lastcrumb') {
                headObj.titleText = `${li.querySelector('span.largecrumb').innerHTML.trim()}${jst ? ' JST' : ''}`;

            }
        })

        // Set up arrow navigation
        const rightArrow = htmlstring.querySelector('a.scripturesrightarrow');
        const leftArrow = htmlstring.querySelector('a.scripturesleftarrow');
        if (rightArrow) {
            const rightArr = rightArrow.getAttribute('onclick').split('&');
            headObj.rightarrow = true;
            headObj.rightarrowbook = parseInt(rightArr[0].split('=')[1]);
            headObj.rightarrowchapter = parseInt(rightArr[1].split('=')[1]);
            headObj.rightarrowverses = rightArr[2].split('=')[1] === "" ? "all" : rightArr[2].split('=')[1];
            headObj.rightarrowjst = rightArr[3].split("'")[0].split('=')[1] === "JST" ? true : false;
        } else {
            headObj.rightarrow = false;
        }
        if (leftArrow) {
            const leftArr = leftArrow.getAttribute('onclick').split('&');
            headObj.leftarrow = true;
            headObj.leftarrowbook = parseInt(leftArr[0].split('=')[1]);
            headObj.leftarrowchapter = parseInt(leftArr[1].split('=')[1]);
            headObj.leftarrowverses = leftArr[2].split('=')[1] === "" ? "all" : leftArr[2].split('=')[1];
            headObj.leftarrowjst = leftArr[3].split("'")[0].split('=')[1] === "JST" ? true : false;
        } else {
            headObj.leftarrow = false;
        }


        // Fill in Remaining Variables with defaults
        headObj.dropdownClass = "menu dropdown-toggle";
        headObj.dropdownDatatoggle = "dropdown";
        headObj.dropdownId = "";
        headObj.print = false;
        headObj.titleClass = "visiblelabel";
        headObj.titleId = "aboutlabel";
        headObj.ulClass = "nav dropdown-menu drop3";
        headObj.ulLabelledby = "drop2";
        headObj.ulRole = "menu";
        headObj.listitems.push({text: "About", style: "", id: ""});
    }


    setHeaderHtml(headObj);
}


/*----------------------------------------------------------------------
 *                      PUBLIC HOOKS
 */
function useFetchHtml() {
    const [isLoading, setIsLoading] = useState(true);
    const [html, setHtml] = useState();
    const [headerHtml, setHeaderHtml] = useState();
    const location = useLocation();
    const contentArr = location.pathname.split('/').slice((location.pathname.split('/').indexOf('ct') + 1))
    const queryType = contentArr[0];
    const content = contentArr[1];
    const dataString = contentArr[2];

    useEffect(() => {
        const data = {}
        let book = 0;
        let chapter = 0;
        if (dataString) {
            const dataArr = dataString.split('-')
            dataArr.forEach(param => {
                param = param.split('=');
                data[param[0]] = param[1];
            })
        }
        if (queryType === 'scripture') {
            const scripContent = content.split('-');
            book = scripContent[0];
            chapter = scripContent[1];
        }
        const query = {
            talk: `${URL_TALKS_AJAX}${content}${data.talkquery ? `/${data.talkquery}` : ''}`,
            scripture: `${URL_SCRIPTURES}${book}${chapter ? `/${chapter}?verses=${data.verses === 'all' ? '' : data.verses}${data.jst === 'true' ? '&jst=JST' : ''}` : ''}`,
            //scripQuery: `${URL_SCRIPTURES}${context.data.scripQuery}`
        }

        fetch(query[queryType])
            .then(response => response.text())
            .then(response => {
                response = fixImgLinks(response);

                if (queryType === 'talk') {
                    response = isolateTalkHeader(response, setHeaderHtml);

                    if (data.citematch) {
                        response = insertCiteMatch(response, data.citematch);
                    }
                }
                if (queryType === 'scripture' || queryType === 'scripQuery') {
                    isolateScriptureHeader(response, setHeaderHtml)
                    response = isolateScriptureContent(response);
                }

                response = fixReferenceLinks(response);
                response = fixPDFLinks(response);
                setHtml(response);
                setIsLoading(false);

                if (document.querySelector('li.match')) {
                    document.querySelector('li.match').scrollIntoView({behavior: "smooth"})
                }
                if (document.getElementById('search')) {
                    document.getElementById('search').scrollIntoView({behavior: "smooth"})
                }
                if (document.querySelector('span.citematch')) {
                    document.querySelector('span.citematch').scrollIntoView({behavior: "smooth"})
                }
                if (document.getElementById(`${data.citematch}`)) {
                    document.getElementById(`${data.citematch}`).scrollIntoView({behavior: "smooth"})
                }
            }
        );
    }, [
        queryType,
        content,
        dataString
    ]);

    return {isLoading, html, headerHtml, queryType};
}

export { useFetchHtml, URL_GS, URL_SX };