import { useEffect } from "react";

// Dropdown functions for Content Header
const useOutsideAlerter = (ref, setDropdown) => {
    useEffect(() => {
        // triggers if you click outside the div
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setDropdown(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setDropdown]);
}

const navAbout = (setDropdown, historyPush, navPath) => {
    historyPush(`/${navPath}ct/talk/about`);
    setDropdown(false);
}

const hideCite = (setDropdown) => {
    let citations = document.querySelectorAll('span.citation');
    citations.forEach((citation) => {
        citation.style.display = 'none';
    });
    document.getElementById('hidecite').style.display = 'none';
    document.getElementById('showcite').style.display = 'block';
    setDropdown(false);
}

const showCite = (setDropdown) => {
    let citations = document.querySelectorAll('span.citation');
    citations.forEach((citation) => {
        citation.style.display = 'inline-block';
    });
    document.getElementById('hidecite').style.display = 'block';
    document.getElementById('showcite').style.display = 'none';
    setDropdown(false);
}

const hideBreak = (setDropdown) => {
    let breaks = document.querySelectorAll('div.break');
    breaks.forEach((brk) => {
        brk.style.display = 'none';
    });
    let hyphens = document.querySelectorAll('div.hyphen');
    hyphens.forEach((hyphen) => {
        hyphen.style.display = 'none';
    });
    document.getElementById('hidebreak').style.display = 'none';
    document.getElementById('showbreak').style.display = 'block';
    setDropdown(false);
}

const showBreak = (setDropdown) => {
    let breaks = document.querySelectorAll('div.break');
    breaks.forEach((brk) => {
        brk.style.display = 'inline';
    });
    let hyphens = document.querySelectorAll('div.hyphen');
    hyphens.forEach((hyphen) => {
        hyphen.style.display = 'inline';
    });
    document.getElementById('hidebreak').style.display = 'block';
    document.getElementById('showbreak').style.display = 'none';
    setDropdown(false);
}

const dropdownFunctions = [navAbout, hideCite, showCite, hideBreak, showBreak]

export { dropdownFunctions, useOutsideAlerter };