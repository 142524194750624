import React, { useState, useRef, useCallback } from 'react';
import {useSearchAPI} from './SearchAPI';
import { NavLink, useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

const EntriesPerPage = 30;

function SearchResultsScroller(props) {
    const location = useLocation();
    const path = location.pathname.substring(1).split('/ct/')[0].split('/');
    const searchTextArr = path[path.length - 1].split('-');
    const searchText = searchTextArr[1]

    const [query] = useState(searchTextArr)
    const [pageNumber, setPageNumber] = useState(0)

    const {
        results,
        hasMore,
        loading,
        error
    } = useSearchAPI(query, pageNumber, props.type)

    const observer = useRef()
    const lastResultElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) {
            setPageNumber(prevPageNumber => prevPageNumber + EntriesPerPage)
          }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])

    return (
        <div>
            <ul className="searchresults" >
                {results.map((result, idx) => {
                    return(
                        <li ref={results.length === idx + 1 ? lastResultElementRef : null} className="grid" key={idx}>
                            {props.type === "scriptures" ?
                                <NavLink
                                    className="waves-effect waves-yellow"
                                    to={`/${path.join('/')}/ct/scripture/${result.book}-${result.chapter}/verses=${result.verses}-jst=${result.jst ? 'true' : 'false'}`}
                                >
                                    <div className="resultTitle">{parse(result.title)}</div>
                                    <div className="resultContext">{parse(result.context)}</div>
                                </NavLink>
                                :
                                <NavLink
                                    className="waves-effect waves-yellow"
                                    to={`/${path.join('/')}/ct/talk/${result.talknum}/talkquery=${searchText}`}
                                >
                                    <div className="resultTitle">{parse(result.title)}</div>
                                    <div className="resultContext">{parse(result.context)}</div>
                                </NavLink>
                            }
                        </li>
                    )
                })}
            </ul>
            <div>{!hasMore && <div className="text-center"><h4>End of Results</h4></div>}</div>
            <div>{loading && <div className="text-center"><h4>Loading...</h4></div>}</div>
            <div>{error && <div className="text-center"><h4>Error</h4></div>}</div>
        </div>
    )
}

export default SearchResultsScroller;
