import React from 'react';
import {NavLink, useLocation} from 'react-router-dom';

function NavTabBar() {
    const location = useLocation();

    const path = location.pathname.split('/');

    const content = path.slice(path.indexOf('ct')).join('/');

    return (
        <div className="tabBar">
            <ul className="tabs">
                <li className={`tab citab waves-effect waves-light ${path[1] === 'citation' || path[1] === '' ? 'selectedtab' : ''}`}
                    style={((path[1] === 'citation' || path[1] === '') && path.length < 3) ? {pointerEvents: 'none'} : {}}
                >
                    <NavLink to={`/citation/Any-1830-2021-all-s-f/${content}`}>
                        <span className="tablabel">Citation Index</span>
                    </NavLink>
                </li>
                <li className={`tab conttab waves-effect waves-light ${path[1] === 'library' ? 'selectedtab' : ''}`}
                    style={path[1] === 'library' && path.length < 3 ? {pointerEvents: 'none'} : {}}
                >
                    <NavLink to={`/library/${content}`}>
                        <span className="tablabel">Library</span>
                    </NavLink>
                </li>
                <li className={`tab speaktab waves-effect waves-light ${path[1] === 'speakers' ? 'selectedtab' : ''}`}
                    style={path[1] === 'speakers' && path.length < 3 ? {pointerEvents: 'none'} : {}}
                >
                    <NavLink to={`/speakers/${content}`}>
                        <span className="tablabel">Speakers</span>
                    </NavLink>
                </li>
                <li className={`tab searchtab waves-effect waves-light ${path[1] === 'search' ? 'selectedtab' : ''}`}
                    style={path[1] === 'search' && path.length < 3 ? {pointerEvents: 'none'} : {}}
                >
                    <NavLink to={`/search/${content}`}>
                        <span className="tablabel">Search</span>
                    </NavLink>
                </li>
            </ul>
      </div>
    )
}

export default NavTabBar;
