import React from 'react';
import { useHistory } from 'react-router-dom';

function SearchScripture(props) {
    const history = useHistory();
    const pathContent = history.location.pathname.split('ct')[1];

    const searchScriptures = () => {
        let error = false;

        let terms = props.getSearchText();
        if (!terms) {
            error = true;
        }

        let chkOT = document.getElementById('OT').checked;
        let chkNT = document.getElementById('NT').checked;
        let chkJSTF = document.getElementById('JSTF').checked;
        let chkBOM = document.getElementById('BOM').checked;
        let chkDC = document.getElementById('DC').checked;
        let chkPGP = document.getElementById('PGP').checked;
        let chkJST = document.getElementById('JST').checked;
        let chkSortByRel = document.getElementById('scripturesSortBy').checked;

        let scriptureString = '';
        if (chkOT) {
            scriptureString += 'o';
        }
        if (chkNT) {
            scriptureString += 'n';
        }
        if (chkJSTF) {
            scriptureString += 'f';
        }
        if (chkBOM) {
            scriptureString += 'b';
        }
        if (chkDC) {
            scriptureString += 'd';
        }
        if (chkPGP) {
            scriptureString += 'p';
        }
        if (chkJST) {
            scriptureString += 'j';
        }
        if (scriptureString.length < 1) {
            scriptureString += 'onbdpf';
        }

        let relString = '';
        if (chkSortByRel) {
            relString = 'r';
        } else {
            relString = 'n';
        }

        let scripArr = ['s', scriptureString, relString, '30@'];

        if (!error) {
            history.push(`/search/results/${scripArr.join('&')}-${encodeURIComponent(terms)}/ct${pathContent}`);
        } else {
            alert("Text box is empty");
        }
    }

    const resetScripturesForm = () => {
        props.resetSearchText();
        document.getElementById('OT').checked = true;
        document.getElementById('NT').checked = true;
        document.getElementById('JSTF').checked = true;
        document.getElementById('BOM').checked = true;
        document.getElementById('DC').checked = true;
        document.getElementById('PGP').checked = true;
        document.getElementById('JST').checked = false;
        document.getElementById('scripturesSortBy').checked = false;
    }

    return (
        <div id="searchScripturesWrapper" className={props.display ? "hidden" : ""}>
            <form id="searchScriptures" onSubmit={() => searchScriptures()}>
                {props.labels.map((label, idx) => {
                    if(idx === props.labels.length - 1) {
                        return(
                            <div key={idx}>
                                <br></br>
                                <label className="checkbox" title={label.title} key={idx}>
                                    <input id={label.inputid} type="checkbox" defaultChecked={false}/>
                                    {label.text}
                                </label>
                            </div>
                        )
                    }

                    return(
                        <label className="checkbox" title={label.title} key={idx}>
                            <input id={label.inputid} type="checkbox" defaultChecked={idx < props.labels.length - 2 ? true : false} />
                            {label.text}
                        </label>
                    )
                })}
            </form>
            <div className="searchBtnWrapper">
                <button id="scriptureSearchBtn" onClick={() => searchScriptures()} title="Start the search" className="btn searchbutton waves-effect">Search</button>
                <button id="resetScripturesForm" onClick={() => resetScripturesForm()} title="Reset search form to defaults" className="btn waves-effect">Reset</button>
            </div>
        </div>
    )
}

export default SearchScripture;