import React, {useState} from 'react';
import LoadingIndicator from './LoadingIndicator';
import { useFetchSearchData } from './SearchAPI';
import SearchScripture from './SearchScripture';
import SearchTalk from './SearchTalk';

function Search(props) {
    const [searchmode, setSearchmode] = useState(true)

    const { content, isLoading } = useFetchSearchData('searchMain', props.active);

    const getSearchText = () => {
        let text = document.getElementById('searchTerms').value;
        return text.replace(/^\s+|\s+$/g, '');
    }

    const resetSearchText = () => {
        document.getElementById('searchTerms').value = "";
    }

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div id="scisearch" className="scicontent nano">
            <div className="nano-content">
                <div id="searchWhatWrapper">
                    <div className="btn-group active" id="searchWhat">
                        <button
                            onClick={() => setSearchmode(true)}
                            id="showTalksButton"
                            title="Search in talks"
                            className={searchmode ? "btn btn-inverse" : "btn waves-effect"}
                            disabled={searchmode}
                        >
                            Talks
                        </button>
                        <button
                            onClick={() => setSearchmode(false)}
                            id="showScripturesButton"
                            title="Search in scriptures"
                            className={!searchmode ? "btn btn-inverse" : "btn waves-effect"}
                            disabled={!searchmode}
                        >
                            Scriptures
                        </button>
                    </div>
                </div>
                <div id="searchInput">
                    <input type="text" id="searchTerms" className="asInput" placeholder="Search" title="Enter your search terms" />
                </div>
                {searchmode ?
                    <SearchTalk
                        display={searchmode}
                        content={content.talksearch}
                        getSearchText={() => getSearchText()}
                        resetSearchText={() => resetSearchText()}
                    />
                    :
                    <SearchScripture
                        display={searchmode}
                        labels={content.scripsearch}
                        getSearchText={() => getSearchText()}
                        resetSearchText={() => resetSearchText()}
                    />
                }
            </div>
        </div>
    )
}

export default Search;
