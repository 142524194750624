import React, {useState} from "react";
import { Route } from "react-router-dom";
import NavHeader from "./NavHeader";
import NavTabBar from "./NavTabBar";
import Library from "./Library";
import ScriptureVolumes from "./ScriptureVolumes";
import ScriptureBook from "./ScriptureBook";
import ConferenceList from "./ConferenceList";
import ConferenceTalks from "./ConferenceTalks";
import JodList from "./JodList";
import JodVolume from "./JodVolume";
import TopicalIndex from "./TopicalIndex";
import StpjsList from "./StpjsList";
import SpeakersTab from "./SpeakersTab";
import SpeakerList from "./SpeakerList";
import SpeakerTalks from "./SpeakerTalks";
import CitationVolumes from "./CitationVolumes";
import CitationBook from "./CitationBook";
import CitationChapter from "./CitationChapter";
import CitationReference from "./CitationReference";
import Search from "./Search";
import SearchResults from "./SearchResults";
import { CSSTransition } from "react-transition-group";
import RedirectBase from "./RedirectBase";

const PATH_CONTENT = "ct/:type/:content/:data?";

const ROUTES = [
    { path: `/library/scriptures/:volume/:book/${PATH_CONTENT}`, Component: ScriptureBook },
    { path: `/library/scriptures/:volume?/${PATH_CONTENT}`, Component: ScriptureVolumes },
    { path: `/library/genconf/:year/:month/${PATH_CONTENT}`, Component: ConferenceTalks },
    { path: `/library/genconf/${PATH_CONTENT}`, Component: ConferenceList },
    { path: `/library/jod/:volume/${PATH_CONTENT}`, Component: JodVolume },
    { path: `/library/jod/${PATH_CONTENT}`, Component: JodList },
    { path: `/library/topidx/:id?/${PATH_CONTENT}`, Component: TopicalIndex },
    { path: `/library/scripteach/${PATH_CONTENT}`, Component: StpjsList },
    { path: `/library/${PATH_CONTENT}`, Component: Library },
    { path: `/speakers/:letter/:speaker/${PATH_CONTENT}`, Component: SpeakerTalks },
    { path: `/speakers/:letter/${PATH_CONTENT}`, Component: SpeakerList },
    { path: `/speakers/${PATH_CONTENT}`, Component: SpeakersTab },
    { path: `/citation/:book/:chapter/:verses/:jst/:filter/${PATH_CONTENT}`, Component: CitationReference },
    { path: `/citation/:book/:chapter/:filter/${PATH_CONTENT}`, Component: CitationChapter },
    { path: `/citation/:book/:filter/${PATH_CONTENT}`, Component: CitationBook },
    { path: `/citation/:filter/${PATH_CONTENT}`, Component: CitationVolumes },
    { path: `/search/results/:searchparams/${PATH_CONTENT}`, Component: SearchResults },
    { path: `/search/${PATH_CONTENT}`, Component: Search },
    { path: "/", Component: RedirectBase },
];

function Navigator(props) {
    const [showCitFilter, setShowCitFilter] = useState(false);
    const [crumbTextArr, setCrumbTextArr] = useState();

    return (
        <div className="sciwrapper">
            <div id="scicrumb" className="citation_crumb" style={{ opacity: 1 }}>
                <NavHeader showCitFilter={showCitFilter} setShowCitFilter={setShowCitFilter} crumbTextArr={crumbTextArr}/>
                <NavTabBar />
            </div>
            {ROUTES.map(({ path, Component }) => (
                <Route key={path} exact path={path}>
                    {({ match }) => {
                        return (
                            <CSSTransition
                                in={match !== null}
                                timeout={350}
                                classNames={"nav-unit"}
                                unmountOnExit
                            >
                                <Component
                                    {...props}
                                    active={match !== null}
                                    setShowCitFilter={setShowCitFilter}
                                    setCrumbTextArr={setCrumbTextArr}
                                />
                            </CSSTransition>
                        );
                    }}
                </Route>
            ))}
        </div>
    );
}

export default Navigator;
