import React from 'react';
import { NavLink, useParams } from 'react-router-dom';
import LoadingIndicator from './LoadingIndicator';
import { useFetchSpeakerData } from './SpeakerAPI';


function SpeakerList(props) {
    const params = useParams();
    const { content, isLoading } = useFetchSpeakerData('speakerList', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                <ul className="speakerlist">
                    {content.map((speaker, idx) => {
                        return(
                            <li key={idx}>
                                <NavLink
                                    to={`/speakers/${params.letter}/${speaker.number}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`}
                                    className="waves-effect waves-yellow"
                                >
                                    {speaker.name}
                                </NavLink>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default SpeakerList;
