import React from "react";
import { NavLink, useParams } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import { useFetchLibraryData } from "./LibraryAPI";

const SKIP_TO_SCRIP = [201, 202, 203, 204, 301]

function ScriptureVolumes(props) {
    const params = useParams();

    const { content, isLoading } = useFetchLibraryData('scripVolumes', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scripturecontent nano has-scrollbar">
            <div className="nano-content" tabIndex="0" style={{ right: "-17px" }}>
                {content.map((volume, outIndex) => {
                    let volumeId = outIndex + 1;
                    return (
                        <div key={volumeId}>
                            <div className="volumetitle">{volume.title}</div>
                            <ul className="volumecontents">
                                {volume.books.map((book, inIndex) => {
                                    let multiplier = (outIndex + 1) * 100;
                                    let toContent = false;
                                    if (outIndex === 1) {
                                        multiplier = 139;
                                    } else if (outIndex > 1) {
                                        multiplier = outIndex * 100;
                                    }
                                    let bookid = multiplier + (inIndex + 1);
                                    if (SKIP_TO_SCRIP.includes(bookid)) {
                                        toContent = true;
                                    }
                                    return (
                                        <li key={bookid}>
                                            <NavLink
                                                className="waves-effect waves-yellow"
                                                to={toContent ?
                                                    `/library/scriptures/${volumeId}/ct/scripture/${bookid}`
                                                    : `/library/scriptures/${volumeId}/${bookid}/ct/${params.type}/${params.content}${params.data ? `/${params.data}` : ''}`
                                                }

                                            >
                                                {book}
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default ScriptureVolumes;
