import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseHTML } from './LibraryAPI';

/*----------------------------------------------------------------------
 *                      CONSTANTS
 */
const URL_SPEAKERS = 'https://scriptures.byu.edu/citation_index/speakers_ajax/';

/*----------------------------------------------------------------------
 *                      PRIVATE HELPERS
 */
const isolateSpeakerListContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let speakerlist = htmlstring.querySelector("ul.speakerlist");
    let speakers = speakerlist.querySelectorAll("a");
    speakers.forEach((speaker) => {
        let speakerobj = {};
        speakerobj.name = speaker.innerHTML.split('<')[0];
        speakerobj.number = speaker.getAttribute('onclick').match(/[^()]+/g)[1].replaceAll("'", "");
        returnobject.push(speakerobj);
    });

    return returnobject;
};

const isolateSpeakerTalksContent = function (htmlstring) {
    htmlstring = parseHTML(htmlstring);
    let returnobject = [];

    let talkslist = htmlstring.querySelector("ul.talksblock");
    let talks = talkslist.querySelectorAll("li");
    talks.forEach((talk) => {
        let talkobj = {};
        let alist = talk.querySelectorAll('a');

        talkobj.session = talk.querySelector('div.speaker').innerHTML;
        talkobj.title = talk.querySelector('div.talktitle').innerHTML;
        alist.forEach((a, idx) => {
            let click = a.getAttribute('onclick').split("'");
            if(idx === 0) {
                talkobj.talknum = click[1];
            }
            else if(idx === 1) {
                talkobj.watchlink = click[3];
            }
            else if(idx === 2) {
                talkobj.listenlink = click[3];
            }
        })
        if(talk.querySelector('div.watchtalk')){
            talkobj.media = true;
        } else {
            talkobj.media = false;
        }
        returnobject.push(talkobj);
    });

    return returnobject;
};

/*----------------------------------------------------------------------
 *                      PUBLIC HOOKS
 */
function useFetchSpeakerData(component, active) {
    const [content, setContent] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const params = useParams();

    useEffect(() => {
        const query = {
            speakerList: `${URL_SPEAKERS}${params.letter}`,
            speakerTalks: `${URL_SPEAKERS}${params.speaker}`
        }
        if (active) {
            fetch(query[component])
                .then((response) => response.text())
                .then((response) => {

                    if (component === 'speakerList') {
                        response = isolateSpeakerListContent(response);
                    }
                    if (component === 'speakerTalks') {
                        response = isolateSpeakerTalksContent(response);
                    }

                    setContent(response);
                    setIsLoading(false);
                }
            );
        }
    }, [component, active, params.letter, params.speaker]);

    return { content, isLoading };
}

/*----------------------------------------------------------------------
 *                      EXPORTS
 */
export { useFetchSpeakerData };