import React from "react";
import { NavLink } from "react-router-dom";
import LoadingIndicator from "./LoadingIndicator";
import { useFetchLibraryData } from "./LibraryAPI";

function StpjsList(props) {
    const { content, isLoading } = useFetchLibraryData('stpjs', props.active);

    if (isLoading) {
        return <LoadingIndicator />;
    }

    return (
        <div className="scicontent nano">
            <div className="nano-content">
                {content.map((section, idx) => {
                    return(
                        <div key={idx}>
                            <div className="sectiontitle">{section.title}</div>
                            <ul className="pagesblock">
                                {section.pages.map((page, idx2) => {
                                    return(
                                        <li key={idx2}>
                                            <NavLink
                                                className="waves-effect waves-yellow"
                                                to={`/library/scripteach/ct/talk/${page.link}`}
                                            >
                                                {page.text}
                                            </NavLink>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default StpjsList;
